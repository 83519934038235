<template>
  <v-container align="center" justify="center">
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Cash in Lieu Reports </span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12" xl="11">
        <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
          <card-title icon="mdi-book" :is-image="false"
            >Cash in Lieu Reports
          </card-title>
          <div v-if="isBookings || search">
            <div class="tw-flex tw-justify-end">
              <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3 md:tw-pb-5">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <hr />
            <v-data-table
              :headers="headers"
              :items="bookings"
              :search.sync="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :loading="loadingTable"
              :page.sync="pagination.pageNumber"
              :items-per-page.sync="pagination.perPage"
              :server-items-length="pagination.totalItems"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30, 40, 50],
              }"
            >
              <template v-slot:[`item.accidentCategory`]="{ item }">
                <v-chip color="warning" small>
                  {{ item.status.replaceAll('_', ' ') }}
                </v-chip>
              </template>
              <template v-slot:[`item.biddingTemplate.make`]="{ item }">
                <p>
                  {{ item.biddingTemplate.make }}
                  {{ item.biddingTemplate.model }}
                </p>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span>{{ item.createdAt | formatToHuman }}</span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div class="tw-flex tw-flex-row">
                  <router-link
                    :to="{
                      name: 'BidDetails',
                      params: { bookingId: item.id, fullReport: true },
                    }"
                    :key="item.action"
                    ><v-icon color="primary" small>mdi-eye</v-icon>
                    View</router-link
                  >
                </div>
              </template>
            </v-data-table>
          </div>
          <div
            v-else
            class="
              tw-py-4
              md:tw-py-14
              tw-flex tw-items-center tw-justify-center tw-flex-col
            "
          >
            <img
              :src="require('@/assets/img/assesment-center/no-car.png')"
              class="tw-h-16 md:tw-h-28"
              alt="no car"
            />
            <p class="tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify">
              There’s no cash in lieu Reports here, please head to the
              <span class="c-blue-text tw-font-bold"
                >previous module in the action panel</span
              >
            </p>
            <v-btn
              :to="{ name: 'AssessmentCenter' }"
              class="tw-w-full md:tw-w-1/3"
              color="warning"
              >Go To Action Panel</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import { formatToHuman } from '@/utils/time'
import bookingsMixin from '@/mixins/bookingsMixin'
export default {
  name: 'CashInLieuReports',
  components: { CardTitle },
  mixins: [bookingsMixin],
  data() {
    return {
      loadingTable: true,
      headers: [
        { text: 'No.', value: 'id' },
        { text: 'Customer', value: 'clientInfo.name', sortable: false },
        { text: 'Make/Model', value: 'biddingTemplate.make', sortable: false },
        { text: 'Reg. No.', value: 'vehicleInfo.registrationNumber' },
        { text: 'Date', value: 'createdAt' },
        { text: 'Phone No.', value: 'clientInfo.phoneNumber', sortable: false },
        { text: 'Status', value: 'accidentCategory' },
        { text: 'Action', value: 'action', sortable: false },
      ],
      bookings: [],
      fetchBookingsUrl:
        '/motor-assessment/api/booking?bookingRequestType=BOOKING_BY_STATUS&bookingStatus=CASH_IN_LIEU',
    }
  },
  computed: {
    isBookings: function () {
      return this.bookings.length > 0 || this.loadingTable
    },
  },
  mounted: function () {
    this.fetchBookings()
    this.sortBy = [false, false, false, false]
  },
  filters: {
    formatToHuman,
  },
}
</script>
